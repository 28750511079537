import { Helmet } from 'react-helmet';
import { isUserLoggedIn, logIn } from '../utils/auth';
import OfferOverlayGroup from './OfferOverlayGroup';
import UserOffersPage from './UserOffersPage';
import FullWidthPageContent from './FullWidthPageContent';
import PageTitle from './PageTitle';
import Button from './Button';
import '../scss/yettel-content.scss';
import MySavedCouponsCount from './MySavedCouponsCount';
import { useFetchMySavedCouponsCount } from '../hooks/useFetchMySavedCouponsCount';

const CouponsPage = () => {
  const isLoggedIn = isUserLoggedIn();
  const { data: savedCouponCountData } = useFetchMySavedCouponsCount();

  const handleLogin = () => {
    logIn();
  };

  return (
    <>
      <Helmet>
        <title>Kuponi | Yettel Shopping</title>
      </Helmet>
      <FullWidthPageContent>
        <PageTitle className="mb-4">
          <section className="d-flex justify-content-between align-items-center">
            <div>Moji kuponi</div>
            {savedCouponCountData?.isActiveContest && (
              <MySavedCouponsCount codeCount={savedCouponCountData.codeCount} countedAt={savedCouponCountData.countedAt}/>
            )}
          </section>
        </PageTitle>
        <div className="mx-auto">
          {isLoggedIn && (
            <>
              <UserOffersPage />
              <OfferOverlayGroup />
            </>
          )}

          {!isLoggedIn && (
            <>
              <p className="text-center">Prijavi se kako bi video svoje sačuvane i istekle kupone.</p>
              <Button className="mt-4 ms-0 mx-auto" onClick={handleLogin}>
                Prijavi se
              </Button>
            </>
          )}
        </div>
      </FullWidthPageContent>
    </>
  );
};

export default CouponsPage;
