import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useEffect } from 'react';
import '../scss/_main.scss';

interface Props {
  children: React.ReactNode;
}

const GlobalWrapper: React.FC<Props> = ({ children }) => {
  const isMutating = useIsMutating({
    predicate: mutation => {
      const mutationKey =
        mutation.options.mutationKey && mutation.options.mutationKey[0] ? mutation.options.mutationKey[0] : '';

      // List of mutations that are exempt from global loading.
      return ![QUERY_KEYS.SAVE_OFFER].includes(mutationKey as string);
    }
  });

  const isFetching = useIsFetching({
    predicate: query => {
      // List of queries that are exempt from global loading.
      return ![
        QUERY_KEYS.GET_LOCATIONS,
        QUERY_KEYS.GET_OFFERS,
        QUERY_KEYS.GET_SEARCH_SUGGESTIONS,
        QUERY_KEYS.GET_OFFER_PARTNER_LOCATION,
        QUERY_KEYS.GET_OFFER_PARTNER_LOCATIONS_LIST,
        QUERY_KEYS.GET_USER_LOCATION,
        QUERY_KEYS.GET_SHOPPING_MALLS,
        QUERY_KEYS.GET_PARTNERS_WITH_COUNT
      ].includes(query.queryKey[0] as string);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {children}
      {(isFetching > 0 || isMutating > 0) && <GlobalLoadingIndicator />}
    </>
  );
};

export default GlobalWrapper;
