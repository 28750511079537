import { Helmet } from 'react-helmet';
import { useJsApiLoader } from '@react-google-maps/api';
import { useQuery } from '@tanstack/react-query';
import { GOOGLE_MAPS_API_KEY } from '../constants/config';
import { Offer } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import { useEffect, useMemo, useState } from 'react';
import FilteredMap from './FilteredMap';
import Button from './Button';
import LoadingIndicator from './LoadingIndicator';
import PageTitle from './PageTitle';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import '../scss/map-page-map.scss';

const MapPage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const specificLat = searchParams.get('lat');
  const specificLng = searchParams.get('lng');

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [mapZoom, setMapZoom] = useState<number>(12);
  const [showMap, setShowMap] = useState(false);
  const categories = useMemo(() => searchParams.get(SEARCH_PARAMS.CATEGORIES)?.split(',') || [], [searchParams]);
  const partners = useMemo(() => searchParams.get(SEARCH_PARAMS.PARTNERS)?.split(',') || [], [searchParams]);

  const isSpecificStoreMap = specificLat && specificLng;

  const { isLoaded: isMapsAPILoaded } = useJsApiLoader({
    id: 'map-section-script-loader',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['geometry', 'drawing']
  });

  const { isSuccess: offersFetched, data: offers } = useQuery<Offer[]>({
    queryKey: [QUERY_KEYS.GET_ALL_OFFERS, categories],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        limit: '1000',
        category_id:categories.toString()
      });

      const response = await callPublicAPI<Offer[]>('GET', '/offers', searchParams);
      return response.data;
    }
  });

  const handleCategoryFilterClick = () => {
    const urlParams = searchParams.toString();
    navigate(`/near-by/categories?${urlParams}`, { replace: true });
  };

  const handleCategoriesAndPartnerFilterClick = () => {
    const urlParams = searchParams.toString();
    navigate(`/near-by/filters?${urlParams}`, { replace: true });
  };

  useEffect(() => {
    setShowMap(false);

    setTimeout(() => {
      setShowMap(true);
    }, 300);
  }, [categories]);

  useEffect(() => {
    if (specificLat && specificLng) {
      const storeLocation = {
        lat: Number(specificLat),
        lng: Number(specificLng),
      };

      setMapCenter(storeLocation);
    }
  }, [isMapsAPILoaded]);

  return (
    <>
      <Helmet>
        <title>Mapa | Yettel Shopping</title>
      </Helmet>

      {isMapsAPILoaded && offersFetched && (
        <section style={{ width: '100%' }}>
          <PageTitle className="mb-2 d-flex-important justify-content-between">
            Mapa
            {!isSpecificStoreMap && (
              <Button className="unset-margins" secondary onClick={handleCategoryFilterClick}>
                Filter kategorija
              </Button>
            )}
            {isSpecificStoreMap && (
              <Button className="unset-margins" secondary onClick={handleCategoriesAndPartnerFilterClick}>
                Filter
              </Button>
            )}
          </PageTitle>

          {!showMap && (
            <div className="d-flex justify-content-center mt-5 pt-5">
              <LoadingIndicator />
            </div>
          )}

          {showMap && (
            <FilteredMap
              partners={partners}
              mapCenter={mapCenter}
              mapZoom={mapZoom}
              setMapCenter={setMapCenter}
              setMapZoom={setMapZoom}
              offers={offers}
            />
          )}
        </section>
      )}
    </>
  );
};

export default MapPage;
