import { useState } from 'react';
import { ApiResponse, User } from '../types/types';
import UserProfileItem from './UserProfileItem';
import Button from './Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { callPublicAPI } from '../utils/api';
import { QUERY_KEYS } from '../constants/queryKeys';
import '../scss/yettel-input.scss';

interface Props {
  user: User;
  toggleBackToView: () => void;
}

const UserProfileEditForm: React.FC<Props> = ({ user, toggleBackToView }) => {
  const [name, setName] = useState<User['name']>(user.name || '');
  const [gender, setGender] = useState<User['gender']>(user.gender || '-');
  const [age, setAge] = useState<User['age']>(user.age || 7);
  const [email, setEmail] = useState(user.email || '');

  const queryClient = useQueryClient();
  const saveChanges = useMutation<ApiResponse<User>>({
    mutationFn: async () => {
      const data = {
        name: name ? name : undefined,
        gender: gender ?? '',
        age: age ?? undefined,
        email: email ? email : undefined
      };

      const response = await callPublicAPI<User>('PUT', '/user-profiles', null, data);
      return response;
    },
    onSuccess: () => {
      queryClient.setQueryData([QUERY_KEYS.GET_USER_DATA], {
        name,
        gender,
        age,
        email,
        msisdn: user.msisdn
      });

      toggleBackToView();
    },
    onError: () => {
      alert('Došlo je do greške prilikom promene ličnih podataka. Molimo Vas pokušajte ponovo kasnije.');
    }
  });

  return (
    <>
      <UserProfileItem
        label="Ime i prezime"
        input={<input className="yettel-input" type="text" value={name} onChange={e => setName(e.target.value)} />}
      />
      <UserProfileItem
        label="Pol"
        input={
          <select className="yettel-input" value={gender} onChange={e => setGender(e.target.value as User['gender'])}>
            <option selected={gender === 'M'} value="M">
              Muški
            </option>
            <option selected={gender === 'Ž'} value="Ž">
              Ženski
            </option>
            <option selected={gender === '-'} value="-">
              -
            </option>
          </select>
        }
      />
      <UserProfileItem
        label="Godina"
        input={
          <input
            className="yettel-input"
            type="number"
            value={age}
            onChange={e => setAge(parseInt(e.target.value))}
            min={7}
            max={120}
            step={1}
          />
        }
      />
      <UserProfileItem
        label="E-mail"
        input={<input className="yettel-input" type="email" value={email} onChange={e => setEmail(e.target.value)} />}
      />

      <Button onClick={() => saveChanges.mutate()} className="mt-4 mb-3">
        Sačuvaj
      </Button>
    </>
  );
};

export default UserProfileEditForm;

