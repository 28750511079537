import { Navigate } from 'react-router-dom';
import useFetchTokensFromRefreshToken from '../hooks/useFetchTokensFromRefreshToken';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';

const RefreshTokens = () => {
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';

  const { isError } = useFetchTokensFromRefreshToken(refreshToken);

  if (isError) {
    return <Navigate to={'/greska-prilikom-autorizacije'} />;
  }

  return <></>;
};

export default RefreshTokens;
