export const QUERY_KEYS = {
  GET_LOCATIONS: 'getLocations',
  GET_USER_SELECTED_LOCATIONS: 'getUserSelectedLocations',
  GET_USER_LOCATION_BY_GPS: 'getUserLocationByGPS',
  GET_CATEGORIES: 'getCategories',
  GET_CATEGORIES_WITH_COUNT: 'getCategoriesWithCount',
  GET_CATEGORIES_WITH_COUNT_ON_MAP: 'getCategoriesWithCountOnMap',

  GET_OFFERS: 'getOffers',
  GET_OFFERS_VARIATION: {
    SORT_POPULAR: 'getOffersSortPopular',
    SORT_DISCOUNT: 'getOffersSortDiscount',
    RECOMMENDED_OFFERS: 'getRecommendedOffers',
    NEWEST_OFFERS: 'getNewestOffers',
    FOR_PARTNER: 'getOffersForPartner',
    FOR_SHOPPING_MALL: 'getOffersForShoppingMall',
    FOR_CATEGORY: 'getOffersForCategory',
    FOR_FILTERS: 'getOffersForFilters',
    FOR_SEARCH_TERM: 'getOffersForSearchTerm'
  },
  GET_ALL_OFFERS: 'getAllOffers',
  GET_OFFER: 'getOffer',
  GET_OFFER_CODE: 'getOfferCode',

  SAVE_OFFER: 'saveOffer',

  GET_SHOPPING_MALLS: 'getShoppingMalls',
  GET_OFFER_FOR_SHOPPING_MALL: 'getOfferForShoppingMall',
  GET_PARTNERS: 'getPartners',
  GET_PARTNERS_WITH_COUNT: 'getPartnersWithCount',
  GET_OFFER_PARTNER_LOCATION: 'getOfferPartnerLocations',
  GET_OFFER_PARTNER_LOCATIONS_LIST: 'getOfferPartnerLocationsList',

  GET_USER_DATA: 'getUserData',
  GET_USER_SELECTED_CATEGORY_IDS: 'getUserSelectedCategoryIds',
  GET_SEARCH_SUGGESTIONS: 'getSearchSuggestions',
  GET_USER_ACTIVE_COUPONS: 'getUserActiveCoupons',
  GET_USER_EXPIRED_COUPONS: 'getUserExpiredCoupons',
  GET_USER_LOCATION: 'getUserLocation',
  GET_SAVED_COUPON_COUNT: 'getSavedCouponCount',

  GET_SCRATCH_OFFERS: 'getScratchOffers',
  GET_YETTEL_PETAK_HISTORY: 'getYettelPetakHistory',
  POST_YETTEL_PETAK_CONSENT: 'postYettelPetakConsent',

  GET_TOKENS_FROM_REFRESH_TOKEN: 'getTokensFromRefreshToken',
  AUTHORIZE_WITH_TOKENS: 'authorizeWithTokens',
  GET_USER_CONSENTS: 'getUserConsents',

  GET_BANNERS: 'getBanners'
};
