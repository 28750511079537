import { Outlet } from 'react-router-dom';
import GlobalWrapper from './GlobalWrapper';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import DailyPrizeModal from './DailyPrizeModal';
import MobileNavigation from './MobileNavigation';
import YettelPetakWizardModal from './YettelPetakWizardModal';
import LoadUtilityClassesLast from './LoadUtilityClassesLast';
import { EndOfGameModal } from './EndOfGameModal';
import { GameResultsModal } from './GameResultsModal';
import { DAILY_PRIZE_MODAL_VISIBLE, END_OF_GAME_MODAL_VISIBLE, GAME_RESULTS_MODAL_VISIBLE } from '../constants/config';
import Authorization from './Authorization';

const Layout = () => {
  const DailyPrizeModalVisible = DAILY_PRIZE_MODAL_VISIBLE === 'true';
  const EndOfGameModalVisible = END_OF_GAME_MODAL_VISIBLE === 'true';
  const GameResultsModalVisible = GAME_RESULTS_MODAL_VISIBLE === 'true';

  return (
    <>
      <Authorization />
      <GlobalWrapper>
        <Header />

        <Content>
          <Outlet />
        </Content>

        <Footer />
        {DailyPrizeModalVisible && <DailyPrizeModal />}
        {EndOfGameModalVisible && <EndOfGameModal />}
        {GameResultsModalVisible && <GameResultsModal />}
        <YettelPetakWizardModal />

        <MobileNavigation />
        <LoadUtilityClassesLast />
      </GlobalWrapper>
    </>
  );
};

export default Layout;
