import { useQuery } from '@tanstack/react-query';
import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import { persistAuthorizationDataToLocalStorage, removeUserDataFromLocalStorage } from '../utils/auth';
import { callPublicAPI } from '../utils/api';
import { QUERY_KEYS } from '../constants/queryKeys';
import { ApiErrorResponse, ApiResponse, Authorization, isApiErrorResponse } from '../types/types';

const useFetchUserData = () => {
  const accessTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) || '';
  const refreshTokenFromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN) || '';

  const { isSuccess, refetch } = useQuery<ApiResponse<Authorization> | ApiErrorResponse>({
    queryKey: [QUERY_KEYS.AUTHORIZE_WITH_TOKENS, accessTokenFromLocalStorage, refreshTokenFromLocalStorage],
    queryFn: async () => {
      const response = await callPublicAPI<Authorization>('POST', '/auth', null, {
        access_token: accessTokenFromLocalStorage,
        refresh_token: refreshTokenFromLocalStorage
      });

      if (response && !isApiErrorResponse(response)) {
        persistAuthorizationDataToLocalStorage(response.data);
      }

      return response;
    },
    retry: 3,
    throwOnError: (_, __) => {
      removeUserDataFromLocalStorage();
      return false;
    }
  });

  return {
    isSuccess,
    refetch
  };
};

export default useFetchUserData;
