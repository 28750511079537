import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { User } from '../types/types';
import { callPublicAPI } from '../utils/api';
import UserProfileSectionTitle from './UserProfileSectionTitle';
import UserProfileDisplay from './UserProfileDisplay';
import { QUERY_KEYS } from '../constants/queryKeys';
import UserProfileEditForm from './UserProfileEditForm';
import UserProfileItem from './UserProfileItem';
import { NALOG } from '../constants/config';

const UserProfile = () => {
  const [viewOrEditUserProfile, setViewOrEditUserProfile] = useState<'view' | 'edit'>('view');

  const { data } = useQuery<User>({
    queryKey: [QUERY_KEYS.GET_USER_DATA],
    queryFn: async () => {
      const response = await callPublicAPI<User>('GET', '/user-profiles');
      return response.data;
    }
  });

  const toggleViewEdit = () => {
    setViewOrEditUserProfile(viewOrEditUserProfile === 'view' ? 'edit' : 'view');
  };

  return (
    <>
      {data && (
        <section className="mb-5">
          <UserProfileSectionTitle>
            <div className="d-flex justify-content-between">
              Osnovni podaci
              <button
                className="text-decoration-underline cursor-pointer font-yettel fz-18"
                onClick={() => toggleViewEdit()}
              >
                {viewOrEditUserProfile === 'view' ? 'Izmeni' : 'Odustani'}
              </button>
            </div>
          </UserProfileSectionTitle>

          {viewOrEditUserProfile === 'view' && <UserProfileDisplay user={data} />}
          {viewOrEditUserProfile === 'edit' && (
            <UserProfileEditForm user={data} toggleBackToView={() => setViewOrEditUserProfile('view')} />
          )}

          <UserProfileItem
            label="Broj telefona"
            value={
              data.msisdn ?? (
                <>
                  <a className="text-decoration-underline" href={NALOG}>
                    Nalog
                  </a>{' '}
                  nije povezan sa brojem telefona
                </>
              )
            }
          />
        </section>
      )}
    </>
  );
};

export default UserProfile;

