import { useSearchParams } from 'react-router-dom';
import {
  authorizeWithJWEToken,
  persistAuthorizationDataToLocalStorage,
  removeUserDataFromLocalStorage
} from '../utils/auth';
import { SEARCH_PARAMS } from '../constants/searchParamNames';
import GlobalLoadingIndicator from './GlobalLoadingIndicator';

interface Props {
  jweToken: string;
  setShouldAuthorize: React.Dispatch<React.SetStateAction<boolean>>;
}

const AuthorizeWithJWEToken: React.FC<Props> = ({ jweToken, setShouldAuthorize }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  authorizeWithJWEToken(jweToken)
    .then(response => {
      removeUserDataFromLocalStorage();
      persistAuthorizationDataToLocalStorage(response.data);

      searchParams.delete(SEARCH_PARAMS.TOKEN);
      searchParams.delete(SEARCH_PARAMS.TOKEN_ALT1);
      searchParams.delete(SEARCH_PARAMS.TOKEN_ALT2);
      setSearchParams(searchParams);
    })
    .catch(error => {
      console.log(error);
      searchParams.delete(SEARCH_PARAMS.TOKEN);
      searchParams.delete(SEARCH_PARAMS.TOKEN_ALT1);
      searchParams.delete(SEARCH_PARAMS.TOKEN_ALT2);
      setSearchParams(searchParams);
    })
    .finally(() => {
      setShouldAuthorize(true);
    });

  return <GlobalLoadingIndicator />;
};

export default AuthorizeWithJWEToken;
