import '../scss/user-profile-section-title.scss';

interface Props {
  children: React.ReactNode;
}

const UserProfileSectionTitle: React.FC<Props> = ({ children }) => {
  return <div className="user-profile-section-title font-yettel-bold fz-22 pb-2 mb-3">{children}</div>;
};

export default UserProfileSectionTitle;

