import { useQuery } from '@tanstack/react-query';
import { getUserConsents, getUserNalogCookieValue, persistUserConsentsInLocalStorage } from '../utils/auth';
import { ApiErrorResponse, ApiResponse, UserConsent, isApiErrorResponse } from '../types/types';
import { QUERY_KEYS } from '../constants/queryKeys';
import { useState } from 'react';

const useFetchConsent = () => {
  const [hasErrored, setHasErrored] = useState(false);

  const nalogCookieValue = getUserNalogCookieValue();

  const { isFetched, isError, data, refetch } = useQuery<ApiResponse<UserConsent> | ApiErrorResponse>({
    queryKey: [QUERY_KEYS.GET_USER_CONSENTS, nalogCookieValue],
    queryFn: async () => {
      const response = await getUserConsents();

      if (isApiErrorResponse(response)) {
        setHasErrored(true);
        return response;
      }

      persistUserConsentsInLocalStorage(response.data);

      return response;
    }
  });

  return {
    isFetched,
    isError: isError || hasErrored,
    data,
    refetch
  };
};

export default useFetchConsent;
