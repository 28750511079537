import { Navigate, useLocation } from 'react-router-dom';
import useFetchConsent from '../hooks/useFetchConsent';
import { shouldRedirectToPrivacySettings } from '../utils/auth';

const CheckConsent: React.FC = () => {
  const location = useLocation();

  const { isFetched: consentsFetched, isError } = useFetchConsent();

  if (isError) {
    return <Navigate to={'/greska-prilikom-autorizacije'} />;
  }

  const shouldRedirect = consentsFetched && shouldRedirectToPrivacySettings();

  if (shouldRedirect) {
    return <Navigate to={`/profil/privatnost?callback=${location.pathname}`} />;
  }

  return <></>;
};

export default CheckConsent;
