import { useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from '../constants/queryKeys';
import { callPublicAPI } from '../utils/api';
import { isUserLoggedIn } from '../utils/auth';

type SavedCouponCount = {
  isActiveContest: boolean;
  codeCount: number;
  countedAt: string; // "2024-08-23T20:22:52.235Z"
};

export const useFetchMySavedCouponsCount = () => {
  const isLoggedIn = isUserLoggedIn();

  return useQuery<SavedCouponCount>({
    queryKey: [QUERY_KEYS.GET_SAVED_COUPON_COUNT],
    queryFn: async () => {
      const response = await callPublicAPI<SavedCouponCount>('GET', '/offer-codes/count');

      return response.data;
    },
    enabled: isLoggedIn
  });
};
